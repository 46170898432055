import { useEffect, useRef, useState } from 'react';
import Button from '../../../CommonComponents/Button/Button';
import Dropdown from '../../../CommonComponents/Dropdown/Dropdown';
import { getStyleForProps } from '@/components/DesignSystem/ModernTheme/utils/getStylesConfig';
import { DownArrowIcon } from '@/assets/svgExports/DownArrowIcon';
import { RightArrowIconTransparent } from '@/assets/svgExports/RightArrowIconTransparent';
import {
  HeaderMobileContainer,
  LogoContainer,
  HamburgerCoantianer,
  NavContainer,
  MainContainer,
  HeaderButtonContainer,
} from './HeaderMobile.styled';
import { btnActionHandler } from '../../../utils/btnUtils';
import { useDispatch } from 'react-redux';
import { toggleFormWidget } from '@/redux/actions';
import store from '@/redux/store';

const HeaderMobile = (props) => {
  const { headerState, handleLogoClick } = props;
  const storeName = store.getState().storeReducer.store?.store_name;
  const dispatch = useDispatch();

  const [dropDownActive, setDropDownActive] = useState({
    openDropDown: false,
    activeLink: '',
  });
  const [openNav, setOpenNav] = useState(false);
  const hamburgerRef = useRef(null);
  const hamburgerContainerRef = useRef(null);
  const dropdownRef = useRef(null);
  const parentLinkRef = useRef(null);
  const propStyle = getStyleForProps(null);
  const openForm = () => {
    dispatch(toggleFormWidget(true));
  };
  const handleNavInteraction = (data) => {
    if (data.actions.type === 5) {
      if (data.actions.handler === '') {
        setDropDownActive((prevState) =>
          prevState?.activeLink === data?.id
            ? {
                openDropDown: false,
                activeLink: '',
              }
            : {
                openDropDown: true,
                activeLink: data?.id,
              }
        );
      }
    } else if (data.actions.type === 2) {
      btnActionHandler(data?.actions, openForm);
      setOpenNav(false);
    } else {
      btnActionHandler(data?.actions);
      setOpenNav(false);
    }
  };
  const getHandler = (data) => handleNavInteraction(data);
  const handleClickOutsideHamburger = (e) => {
    if (
      hamburgerRef.current &&
      !hamburgerRef.current.contains(e.target) &&
      hamburgerContainerRef.current &&
      !hamburgerContainerRef.current.contains(e.target)
    ) {
      setOpenNav(false);
    }
  };
  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutsideHamburger);
    return () => {
      document.removeEventListener('mousedown', handleClickOutsideHamburger);
    };
  }, []);
  const handleClickOutside = (event) => {
    if (parentLinkRef.current && !parentLinkRef.current.contains(event.target)) {
      setDropDownActive({ openDropDown: false, activeLink: '' });
    }
  };
  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);
  const getStoreLogo = (storeLogoData) => {
    return storeLogoData?.content?.store_logo_url ? (
      <img
        className="store_logo_image"
        src={storeLogoData?.content?.store_logo_url}
        alt="store_logo"
        width={
          storeLogoData?.styleConfig?.width
            ? `${parseInt(storeLogoData?.styleConfig?.width) * 1}px`
            : '0px'
        }
        height={
          storeLogoData?.styleConfig?.height
            ? `${parseInt(storeLogoData?.styleConfig?.width) * 1}px`
            : '0px'
        }
        onError={(e) => (e.target.src = storeLogoData?.content?.store_logo_url)}
        style={{ cursor: 'pointer' }}
        onClick={handleLogoClick}
      />
    ) : (
      <span className="store_logo_name">{storeName}</span>
    );
  };
  const getHeaderButtons = (storeButtonData) => {
    return storeButtonData?.content.map((btnData, idx) => (
      <Button
        key={btnData?.id || idx}
        btnData={btnData}
        primaryButton={propStyle?.buttonPrimary}
        secondaryButton={propStyle?.buttonSecondary}
        callback={openForm}
        handleClose={() => setOpenNav(false)}
      />
    ));
  };
  const getNavLinks = (navLinkData) => {
    return navLinkData?.content?.map((nav) =>
      nav?.name?.length > 0 ? (
        <div
          key={nav?.id}
          className="nav-links"
          onClick={() => {
            getHandler(nav);
          }}
        >
          <div className="links">
            {' '}
            {nav?.name}{' '}
            {nav?.actions?.type === 5 &&
              nav?.children?.length > 0 &&
              (dropDownActive?.activeLink === nav?.id ? (
                <DownArrowIcon />
              ) : (
                <RightArrowIconTransparent color="black" />
              ))}{' '}
          </div>
          {nav?.actions?.type === 5 &&
          dropDownActive?.activeLink === nav?.id &&
          nav?.children?.length > 0 ? (
            <Dropdown
              className={`${dropDownActive?.openDropDown}`}
              ref={dropdownRef}
              dropDownData={nav?.children}
              handler={getHandler}
              width="24px"
              height="24px"
              themeConfig={propStyle}
            />
          ) : null}
        </div>
      ) : null
    );
  };
  return (
    <HeaderMobileContainer>
      <LogoContainer themeConfig={propStyle} styleConfig={headerState?.styleConfig}>
        {(headerState?.store_logo?.content?.store_logo_url || storeName) &&
          getStoreLogo(headerState?.store_logo)}
      </LogoContainer>
      <HamburgerCoantianer
        themeConfig={propStyle}
        ref={hamburgerRef}
        className={`hamburger-menu ${openNav ? 'open' : ''}`}
        onClick={() => {
          setOpenNav(!openNav);
        }}
      >
        <div className={`bar1 ${openNav ? 'activeBar1' : ''}`} />
        <div className={`bar2 ${openNav ? 'activeBar2' : ''}`} />
        <div className={`bar3 ${openNav ? 'activeBar3' : ''}`} />
      </HamburgerCoantianer>
      <MainContainer
        themeConfig={propStyle}
        styleConfig={headerState?.styleConfig}
        logoSize={headerState?.store_logo?.styleConfig?.height}
        className={` main-container ${openNav ? 'activate-menu' : ''}`}
        ref={hamburgerContainerRef}
      >
        <NavContainer themeConfig={propStyle} ref={parentLinkRef}>
          {headerState?.nav_links?.content && getNavLinks(headerState?.nav_links)}
        </NavContainer>
        <HeaderButtonContainer>
          {headerState?.button?.content && getHeaderButtons(headerState?.button)}
        </HeaderButtonContainer>
      </MainContainer>
    </HeaderMobileContainer>
  );
};
export default HeaderMobile;
