import styled from 'styled-components';
import { hexToRgba } from '../../../utils/common';

export const HeaderMobileContainer = styled.div`
  padding: 0.75rem 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
`;
export const LogoContainer = styled.div`
  width: fit-content;
  color: ${(props) => props?.themeConfig?.buttonSecondary?.color};
`;
export const MainContainer = styled.div`
  display: none;
  flex-direction: column;
  position: absolute;
  width: 100%;
  position: absolute;
  width: 100%;
  background: ${(props) => props?.themeConfig?.backgroundColor || '#fff'};
  top: ${(props) => (props?.logoSize ? parseInt(props?.logoSize) : 50) + 29}px;
  z-index: 99999;
  left: 0;
  border-bottom: 1px solid
    ${(props) => hexToRgba(`${props.themeConfig.borderColor}`, 0.4)};

  &.main-container.activate-menu {
    display: flex !important;
  }
`;
export const NavContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 1rem;

  .nav-links {
    display: flex;
    flex-direction: column;
  }
  .nav-links .links {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    padding: 1.5rem;
    cursor: pointer;
    font-size: 1rem;
    color: ${(props) => props?.themeConfig?.buttonSecondary?.color};
    font-weight: ${(props) => props?.themeConfig?.buttonSecondary?.fontWeight || '500'};
    border-bottom: 1px solid
      ${(props) => hexToRgba(`${props.themeConfig.borderColor}`, 0.1)};
  }
  .nav-links svg path {
    fill: currentColor;
  }
  & .dropdown-container {
    position: relative;
    top: 0;
    width: 100%;
    color: ${(props) => props?.themeConfig?.body?.color};
  }
  & .dropdown-container > .nav-links {
    padding: 1.5rem 1.5rem 1.5rem 2.5rem;
  }
`;
export const HeaderButtonContainer = styled.div`
  padding: 1rem;
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
  & > button {
    width: 100% !important;
    padding: 13px 12px;
  }
`;
export const HamburgerCoantianer = styled.div`
  & .hamburger-menu {
    display: flex;
    flex-direction: column;
    cursor: pointer;
    transition: transform 0.3s ease-in-out;
  }
  & .bar1,
  & .bar2,
  & .bar3 {
    width: 30px;
    height: 2px;
    background-color: ${(props) => props?.themeConfig?.heading?.color || '#000'};
    margin: 6px 0;
    transition: transform 0.3s ease-in-out;
  }
  .activeBar1 {
    transform: rotate(-45deg) translate(-5px, 6px) !important;
  }

  .activeBar2 {
    opacity: 0;
  }

  .activeBar3 {
    transform: rotate(45deg) translate(-5px, -6px) !important;
  }
`;
