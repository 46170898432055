import styled from 'styled-components';

export const HeaderDesktopContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media screen and (min-width: 768px) {
    padding: 1rem 2.5rem;
    gap: 2.5rem;
  }
  @media screen and (max-width: 767px) {
    padding: 0.75rem 1rem;
    gap: 0;
  }
`;
export const LogoContainer = styled.div`
  color: ${(props) => props?.themeConfig?.buttonSecondary?.color || '#FFF'};
`;
export const NavContainer = styled.div`
  flex: 1;
  display: flex;
  justify-content: ${(props) => props?.styleConfig?.horizontalAlign || 'flex-start'};
  gap: 1.5rem;

  .nav-links {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    cursor: pointer;
    position: relative;
    font-size: 1rem;
    font-family: ${(props) => props?.themeConfig?.buttonSecondary?.fontFamily || 'Arial'};
    color: ${(props) => props?.themeConfig?.buttonSecondary?.color};
    font-weight: ${(props) => props?.themeConfig?.buttonSecondary?.fontWeight || '500'};
    font-family: ${(props) => props?.themeConfig?.buttonSecondary?.fontFamily || 'Arial'};
  }
  .nav-links:hover {
    // padding-bottom: 0.5rem;
    border-bottom: 1px solid ${(props) => props?.themeConfig?.buttonSecondary?.color};
  }

  .nav-links svg path {
    fill: currentColor;
  }
  & .dropdown-container {
    background: ${(props) => props?.themeConfig?.backgroundColor || '#fff'};
    z-index: 100;
  }
`;
export const HeaderButtonContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
`;
