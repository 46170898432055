import { useEffect, useRef, useState } from 'react';
import Button from '../../../CommonComponents/Button/Button';
import Dropdown from '../../../CommonComponents/Dropdown/Dropdown';
import { getStyleForProps } from '@/components/DesignSystem/ModernTheme/utils/getStylesConfig';
import { DownArrowIcon } from '@/assets/svgExports/DownArrowIcon';
import {
  HeaderDesktopContainer,
  LogoContainer,
  NavContainer,
  HeaderButtonContainer,
} from './HeaderDesktop.styled';
import { btnActionHandler } from '../../../utils/btnUtils';
import { useDispatch } from 'react-redux';
import { toggleFormWidget } from '@/redux/actions';
import store from '@/redux/store';

const HeaderDesktop = (props) => {
  const { headerState, handleLogoClick } = props;
  const dispatch = useDispatch();
  const [dropDownActive, setDropDownActive] = useState({
    openDropDown: false,
    activeLink: '',
  });
  const dropdownRef = useRef(null);
  const parentLinkRef = useRef(null);
  const propStyle = getStyleForProps(null);
  const handleNavInteraction = (data) => {
    if (data.actions.type === 5) {
      if (data.actions.handler === '') {
        setDropDownActive((prevState) =>
          prevState?.activeLink === data?.id
            ? {
                openDropDown: false,
                activeLink: '',
              }
            : {
                openDropDown: true,
                activeLink: data?.id,
              }
        );
      }
    } else if (data.actions.type === 2) {
      btnActionHandler(data?.actions, openForm);
      setDropDownActive({
        openDropDown: false,
        activeLink: '',
      });
    } else {
      btnActionHandler(data?.actions);
      setDropDownActive({
        openDropDown: false,
        activeLink: '',
      });
    }
  };
  const handleClickOutside = (event) => {
    if (
      parentLinkRef.current &&
      !parentLinkRef.current.contains(event.target) &&
      dropdownRef.current &&
      !dropdownRef.current.contains(event.target)
    ) {
      setDropDownActive({ openDropDown: false, activeLink: '' });
    }
  };
  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const openForm = () => {
    dispatch(toggleFormWidget(true));
  };

  const storeName = store.getState().storeReducer.store?.store_name;

  const getStoreLogo = (storeLogoData) => {
    return storeLogoData?.content?.store_logo_url ? (
      <img
        className="store_logo_image"
        src={storeLogoData?.content?.store_logo_url}
        alt="store_logo"
        onError={(e) => (e.target.src = storeLogoData?.content?.store_logo_url)}
        width={
          storeLogoData?.styleConfig?.width
            ? `${parseInt(storeLogoData?.styleConfig?.width) * 1}px`
            : '0px'
        }
        height={
          storeLogoData?.styleConfig?.height
            ? `${parseInt(storeLogoData?.styleConfig?.height) * 1}px`
            : '0px'
        }
        style={{ cursor: 'pointer' }}
        onClick={handleLogoClick}
      />
    ) : (
      <span className="store_logo_name">{storeName}</span>
    );
  };
  const getHeaderButtons = (storeButtonData) => {
    return storeButtonData?.content.map((btnData, idx) => (
      <Button
        key={btnData?.id || idx}
        btnData={btnData}
        primaryButton={propStyle?.buttonPrimary}
        secondaryButton={propStyle?.buttonSecondary}
        callback={openForm}
        handleClose={() =>
          setDropDownActive({
            openDropDown: false,
            activeLink: '',
          })
        }
      />
    ));
  };
  const getHandler = (data) => handleNavInteraction(data);
  const checkIfToBeApplied = (navSpace) => {
    if (navSpace === 'flex-end') {
      return '-7px';
    }
  };
  const getNavLinks = (navLinkData) => {
    return navLinkData?.content?.map((nav, idx) =>
      nav?.name?.length > 0 ? (
        <div
          key={nav?.id}
          className="nav-links"
          onClick={() => getHandler(nav)}
          ref={parentLinkRef}
        >
          {nav?.name}{' '}
          {nav?.actions?.type === 5 && nav?.children?.length > 0 && (
            <DownArrowIcon color="black" />
          )}{' '}
          {nav?.actions?.type === 5 &&
            dropDownActive?.activeLink === nav?.id &&
            nav?.children?.length > 0 && (
              <Dropdown
                ref={dropdownRef}
                dropDownData={nav?.children}
                handler={getHandler}
                width="20px"
                height="20px"
                rightPx={checkIfToBeApplied(navLinkData?.styleConfig?.horizontalAlign)}
                logoSize={headerState?.store_logo?.styleConfig?.height}
                themeConfig={propStyle}
              />
            )}
        </div>
      ) : (
        <></>
      )
    );
  };

  return (
    <HeaderDesktopContainer>
      <LogoContainer themeConfig={propStyle} styleConfig={headerState?.styleConfig}>
        {(headerState?.store_logo?.content?.store_logo_url || storeName) &&
          getStoreLogo(headerState?.store_logo)}
      </LogoContainer>
      <NavContainer
        styleConfig={headerState?.nav_links?.styleConfig}
        themeConfig={propStyle}
      >
        {headerState?.nav_links?.content && getNavLinks(headerState?.nav_links)}
      </NavContainer>
      <HeaderButtonContainer>
        {headerState?.button?.content && getHeaderButtons(headerState?.button)}
      </HeaderButtonContainer>
    </HeaderDesktopContainer>
  );
};
export default HeaderDesktop;
