import { useState, useEffect } from 'react';
import { transformWidgetProps } from '@/components/WidgetMaker/utils/common';
import { getStyleForProps } from '@/components/DesignSystem/ModernTheme/utils/getStylesConfig';
import HeaderDesktop from './component/HeaderDesktop';
import HeaderMobile from './component/HeaderMobile';
import { HeaderContainer } from './Header1.styled';
import { useSelector } from 'react-redux';
import { useRouter } from 'next/router';
import { PATH, getRoute } from '@/utils/routes';
import { useSSRSelector } from '@/redux/ssrStore';

const Header1 = (props) => {
  const [headerState, setHeaderState] = useState(
    transformWidgetProps(props?.config?.children)
  );
  const [IS_DESKTOP, setIS_DESKTOP] = useState(false);

  const checkWindowSize = () => {
    if (typeof window !== 'undefined') {
      const isMobile = window.innerWidth < 768; // You can adjust this threshold
      setIS_DESKTOP(!isMobile);
    }
  };

  useEffect(() => {
    // Check window size on component mount
    checkWindowSize();

    // Add event listener for window resize only on the client side
    const handleResize = () => {
      checkWindowSize();
    };

    if (typeof window !== 'undefined') {
      window.addEventListener('resize', handleResize);

      // Cleanup the event listener on component unmount
      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }
  }, []);

  useEffect(() => {
    setHeaderState(transformWidgetProps(props?.config?.children));
  }, [props]);

  const { storeData } = useSSRSelector((state) => ({
    storeData: state.storeReducer.store,
  }));
  const { widgetData } = useSelector((state) => ({
    widgetData: state.widgetsReducer,
  }));
  const [propStyle, setPropStyle] = useState(storeData?.theme?.style_template);

  useEffect(() => {
    if (widgetData?.activeStyleTemplate) {
      setPropStyle(getStyleForProps(widgetData?.activeStyleTemplate));
    } else {
      setPropStyle(getStyleForProps(storeData?.theme?.style_template));
    }
  }, [widgetData?.activeStyleTemplate, storeData?.theme?.style_template]);

  const router = useRouter();
  const handleLogoClick = () =>
    router.push(getRoute(PATH.PRODUCT, storeData?.store_info?.domain));

  return (
    <HeaderContainer
      id="Header"
      styleConfig={propStyle}
      propsBackground={headerState?.media?.background}
      themeConfig={propStyle}
    >
      {IS_DESKTOP ? (
        <HeaderDesktop
          headerState={headerState}
          setHeaderState={setHeaderState}
          handleLogoClick={handleLogoClick}
        />
      ) : (
        <HeaderMobile
          headerState={headerState}
          setHeaderState={setHeaderState}
          handleLogoClick={handleLogoClick}
        />
      )}
    </HeaderContainer>
  );
};
export default Header1;
